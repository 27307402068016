import React, { useEffect, useState } from 'react'

import logo_icon_28 from '../assets/images/logo-icon-28.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';


export default function Navbar() {
  const [isOpen, setMenu] = useState(true);

  useEffect(() => {
    activateMenu();
  });

  window.addEventListener("scroll", windowScroll);

  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("nav-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("nav-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  const toggleMenu = () => {
    setMenu(!isOpen)
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
      anchorArray.forEach(element => {
        element.addEventListener('click', (elem) => {
          const target = elem.target.getAttribute("href")
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        })
      });
    }
  }

  const getClosest = (elem, selector) => {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
          Element.prototype.matchesSelector ||
          Element.prototype.mozMatchesSelector ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.oMatchesSelector ||
          Element.prototype.webkitMatchesSelector ||
          function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {
            }
            return i > -1;
          };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;

  };
  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');


        var immediateParent = getClosest(matchingMenuItem, 'li');

        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(immediateParent, '.child-menu-item');
        if (parent) {
          parent.classList.add('active');
        }

        var parent = getClosest(parent || immediateParent, '.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }

          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  }

  const metamask = async () => {
    try {
      //Basic Actions Section
      const onboardButton = document.getElementById('connectWallet')

      //   metamask modal
      const modal = document.getElementById('modal-metamask')
      const closeModalBtn = document.getElementById('close-modal')

      //   wallet address
      const myPublicAddress = document.getElementById('myPublicAddress')

      //Created check function to see if the MetaMask extension is installed
      const isMetaMaskInstalled = () => {
        //Have to check the ethereum binding on the window object to see if it's installed
        const { ethereum } = window
        return Boolean(ethereum && ethereum.isMetaMask)
      }

      const onClickConnect = async () => {
        if (!isMetaMaskInstalled()) {
          //meta mask not installed
          modal.classList.add('show')
          modal.style.display = 'block'
          return
        }
        try {
          // eslint-disable-next-line no-undef
          await ethereum.request({ method: 'eth_requestAccounts' })
          // eslint-disable-next-line no-undef
          const accounts = await ethereum.request({ method: 'eth_accounts' })
          myPublicAddress.innerHTML =
              accounts[0].split('').slice(0, 6).join('') +
              '...' +
              accounts[0]
                  .split('')
                  .slice(accounts[0].length - 7, accounts[0].length)
                  .join('')
        } catch (error) {
          console.error(error)
        }
      }

      const closeModal = () => {
        modal.classList.remove('show')
        modal.style.display = 'none'
      }

      if (isMetaMaskInstalled()) {
        // eslint-disable-next-line no-undef
        const accounts = await ethereum.request({ method: 'eth_accounts' })
        if (!!accounts[0]) {
          myPublicAddress.innerHTML =
              accounts[0].split('').slice(0, 6).join('') +
              '...' +
              accounts[0]
                  .split('')
                  .slice(accounts[0].length - 7, accounts[0].length)
                  .join('')
        }
      }

      onboardButton.addEventListener('click', onClickConnect)
      closeModalBtn.addEventListener('click', closeModal)
    } catch (error) {
    }
  }

  return (
      <>
        <nav id="topnav" className="defaultscroll is-sticky">
          <div className="container">
            {/* <!-- Logo container--> */}
            <a className="logo ps-0">
              <img src={logo_icon_28} className="inline-block sm:hidden" width={28} alt=""/>
              <div className="sm:block hidden">
                <img src={logo_dark} className="inline-block dark:hidden h-7" alt=""/>
                <img src={logo_white} className="hidden dark:inline-block h-14" alt=""/>
              </div>
            </a>

            <div className="menu-extras">
              <div className="menu-item">
                {/* <!-- Mobile menu toggle--> */}
                <a href="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </div>
            </div>

            <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
              <ul className="navigation-menu justify-end">
                <li className="has-submenu parent-menu-item">
                  <a className="cursor-pointer text-main" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' })}}>Home</a>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <a href="https://launch.cheersland.org/#/fundraising" target="_blank" rel="noreferrer">IGO</a>
                </li>

                <li><a href="https://launch.cheersland.org/#/mining" className="sub-menu-item" target="_blank" rel="noreferrer">Farm</a>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <a href="https://cheersland.org/staking.html" target="_blank" rel="noreferrer">Stake2Earn</a>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <a href="https://galxe.com/cheersland" target="_blank" rel="noreferrer">NFT</a>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <a onClick={() => {
                    window.alert('comming soon!')
                  }}>RWA Market</a>
                </li>

                <li><a className="sub-menu-item cursor-pointer" onClick={() => { window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}}>Contact</a></li>
              </ul>
            </div>
          </div>
        </nav>

      </>
  )
}
