import React from 'react'
import { Hexagon } from 'react-feather';
import {TbSitemap, LuLayers, TbCameraPlus} from '../assets/icons/vander'

export default function Feature() {
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Unleash the Potential of Real World Assets</h3>

                <p className="text-slate-400 max-w-xl mx-auto">A new era of finance where traditional and digital assets seamlessly blend</p>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">

                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 mx-auto rotate-[30deg]" style={{ fill: 'rgba(250, 251, 93, 0.05)'}}></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center" style={{ color: '#FAFB5D' }}>
                            <TbSitemap/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <a href="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-mainText">Tokenization of Real World Assets</a>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Cheersland specializes in transforming any tangible asset into Web3. This process releases the potential of physical items, unlocking great value for Real World Assets while empowering Web3 to offer even more possibilities.</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 mx-auto rotate-[30deg]" style={{ fill: 'rgba(250, 251, 93, 0.05)'}}></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-mainText rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center" style={{ color: '#FAFB5D' }}>
                            <LuLayers/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <a href="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-mainText">Hold or Redeem – The Choice is Yours</a>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">After purchasing an NFT, users have the flexibility to either retain it for potential long-term benefits, such as sharing platform profits and receiving continuous token rewards,  or redeem it for the actual physical asset.</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 mx-auto rotate-[30deg]" style={{ fill: 'rgba(250, 251, 93, 0.05)'}}></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-mainText rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center" style={{ color: '#FAFB5D' }}>
                            <TbCameraPlus/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <a href="" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-mainText">Exclusive Brand Partnerships</a>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Our platform is completely changing traditional trading by collaborating with brands from different fields to transform real-world assets into NFTs. From exclusive sneakers to unique collectibles, we're bringing tangible assets onto the Web3, offering an innovative way to trade and invest.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
