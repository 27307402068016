import './App.css';
import React, { useEffect } from 'react';
import IndexTwo from './pages/index/index-two';

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('dark');
    document.body.classList.add('font-urbanist', 'text-base', 'text-black', 'dark:text-white', 'dark:bg-slate-900');

  });
  return <IndexTwo />
}

export default App;
