import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/avatar/2.jpg';
import image3 from '../assets/images/items/3.gif';
import image4 from '../assets/images/avatar/3.jpg';
import image5 from '../assets/images/avatar/4.jpg';
import image6 from '../assets/images/avatar/5.jpg';
import image7 from '../assets/images/avatar/6.jpg';
import image8 from '../assets/images/items/5.gif';
import image9 from '../assets/images/avatar/7.jpg';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';

export const data = [
     {
        id:1,
        image: item1,
        // subtext: '@StreetBoy',
        // title: 'Genuine Undead #3902',
        // avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: item2,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: item3,
            subtext: '@ButterFly',
            title: 'Probably A Label #3277',
            avatar: image3,
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: item4,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4,
            name:"Mari Harrington",
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, {
            id:5,
            image: item5,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            name:"Floyd Glasgow",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Video',
            subImage:[
                {
                    index:13,
                    image:item15,
                },
                {
                    index:14,
                    image:item9,
                },
                {
                    index:15,
                    image:item12,
                },
            ]
        }, {
            id:6,
            image: item6,
            subtext: '@Angel',
            title: 'Probably A Label #650',
            avatar: image6,
            name:"Donna Schultz",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:16,
                    image:item10,
                },
                {
                    index:17,
                    image:item3,
                },
                {
                    index:18,
                    image:item11,
                },
            ]
        }, {
            id:7,
            image: item7,
            subtext: '@CrazyAnyone',
            title: 'Looki#0147',
            avatar: image7,
            name:"Joshua Morris",
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:19,
                    image:item16,
                },
                {
                    index:20,
                    image:item17,
                },
                {
                    index:21,
                    image:item18,
                },
            ]
        }, {
            id:8,
            image: item8,
            subtext: '@Princess',
            title: 'Poob #285',
            avatar: image8,
            name:"Rosaria Vargas",
            liveDate:'April 15, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:22,
                    image:item7,
                },
                {
                    index:24,
                    image:item9,
                },
                {
                    index:24,
                    image:item19,
                },
            ]
        },
        {
            id:9,
            image: item9,
            subtext: '@LooserBad',
            title: 'Umber Arrow',
            avatar: image9,
            name:"Carl Williams",
            liveDate:'May 17, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:25,
                    image:item22,
                },
                {
                    index:26,
                    image:item23,
                },
                {
                    index:27,
                    image:item24,
                },
            ]
        },
        {
            id:10,
            image: item10,
            subtext: '@Princess',
            title: 'Gloam Druid',
            avatar: image10,
            name:"Rosaria Vargas",
            liveDate:'August 8, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:28,
                    image:item25,
                },
                {
                    index:29,
                    image:item19,
                },
                {
                    index:30,
                    image:item26,
                },
            ]
        },
        {
            id:11,
            image: item11,
            subtext: '@PandaOne',
            title: 'Azuki #7421',
            avatar: image11,
            name:"Julius Canale",
            liveDate:'December 27, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:31,
                    image:item27,
                },
                {
                    index:32,
                    image:item24,
                },
                {
                    index:33,
                    image:item28,
                },
            ]
        },
        {
            id:12,
            image: item12,
            subtext: '@FunnyGuy',
            title: 'Wolf-Cult Vanguard',
            avatar: image12,
            name:"Michael Williams",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:34,
                    image:item7,
                },
                {
                    index:35,
                    image:item14,
                },
                {
                    index:36,
                    image:item19,
                },
            ]
        },
]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'Mindfulness Activities for Kids & Toddlers with NFT',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Save Thousands Of Lives Through This NFT',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'A place where technology meets craftsmanship',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'NFT Market - A Compact Trike with the Big Benefits',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
    {
        id:5,
        title: 'Honoring Black History Month with Toddlers',
        image: blog5,
        category: 'Games',
        subtext: '@@BigBull',
        date:"6th June, 2023"
    },
    {
        id:6,
        title: 'Setting Intentions Instead of Resolutions for 2021',
        image: blog6,
        category: 'Memes',
        subtext: '@Angel',
        date:'19th July, 2023'
    },
    {
        id:7,
        title: 'Clever Ways to Purchase Extraordinart Items',
        image: blog7,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"20th July, 2023"
    },
    {
        id:8,
        title: 'How to Save Money on Baby Essentials for NFT',
        image: blog8,
        category: 'Video',
        subtext: '@Princess',
        date:"31st August, 2023"
    },
    {
        id:9,
        title: 'Liki Trike - A Compact Trike with the Big Benefits',
        image: blog9,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]
