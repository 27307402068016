import React from 'react'
import logo_white from '../assets/images/logo-white.png';
import { BsPencil, FaRegEnvelope, MdKeyboardArrowRight } from "../assets/icons/vander"

export default function Footer() {
  return (
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="relative py-16">
              <div className="relative w-full">
                <div
                    className="relative -top-40 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="md:text-start text-center z-1">
                      <h3 className="text-[26px] font-semibold text-slate-900 dark:text-white">Subscribe to
                        Newsletter!</h3>
                      <p className="text-slate-400 max-w-xl mx-auto">Subscribe to get latest updates and
                        information.</p>
                    </div>

                    <div className="subcribe-form z-1">
                      <form className="relative max-w-lg md:ms-auto">
                        <input type="email" id="subcribe" name="email"
                               className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
                               placeholder="Enter your email :"/>
                        <button type="submit"
                                className="btn absolute top-[2px] end-[3px] h-[46px] bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-black rounded-full">Subscribe
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="absolute -top-5 -start-5">
                    <FaRegEnvelope className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"/>
                  </div>

                  <div className="absolute -bottom-5 -end-5">
                    <BsPencil className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"/>
                  </div>
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                  <div className="lg:col-span-4 md:col-span-12">
                    <a href="#" className="text-[22px] focus:outline-none">
                      <img src={logo_white} alt=""/>
                    </a>
                    <p className="mt-6 text-gray-300">Cheersland is a non-programming modular RWA trading platform
                      designed to bridge web2 and web3.</p>

                  </div>

                  <div className="lg:col-span-2 md:col-span-4">
                  </div>
                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Explorer</h5>
                    <ul className="list-none footer-list mt-6">
                      <li><a href="https://launch.cheersland.org/#/fundraising"
                                className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> IGO</a></li>
                      <li className="mt-[10px]"><a href="https://launch.cheersland.org/#/mining"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> Farm</a></li>
                      <li className="mt-[10px]"><a href="https://cheersland.org/staking.html"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> Stake2Earn</a></li>
                      <li className="mt-[10px]"><a href="https://galxe.com/cheersland"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> NFT</a></li>
                      <li className="mt-[10px]">
                        <a onClick={() => { window.alert('comming soon!')}} className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center">
                          <MdKeyboardArrowRight className="me-1 text-lg"/> RWA Market
                        </a>
                      </li>
                      <li className="mt-[10px]"><a href="https://docs.cheersland.org/"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> Docs</a></li>
                    </ul>
                  </div>

                  <div className="lg:col-span-3 md:col-span-4">
                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Contact</h5>
                    <ul className="list-none footer-list mt-6">
                      <li><a href="https://twitter.com/cheers_land"
                                className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> X</a></li>
                      <li className="mt-[10px]"><a href="https://medium.com/cheersland"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> Blog</a></li>
                      <li className="mt-[10px]"><a href="mailto:info@cheersland.org"
                                                      className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                          className="me-1 text-lg"/> Email</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
          <div className="container text-center">
            <div className="grid md:grid-cols-2 items-center gap-6">
              <div className="md:text-start text-center">
                <p className="mb-0 text-gray-300">© {(new Date().getFullYear())}{" "} CheersLand</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}
