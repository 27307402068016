import React from 'react'
import { Mail } from "react-feather";

export default function GetTouch() {
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Need Assistance?</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Please don’t hesitate to contact us if you have any proposals or difficulties. </p>

                <div className="mt-6">
                    <a href="mailto:info@cheersland.org" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-black rounded-full me-2 mt-2 inline-flex items-center">
                      <Mail className="w-5 h-5 text-black me-1"></Mail>
                      Contact us
                    </a>
                </div>
            </div>
        </div>
    )
}
